import { FeeInfo } from "./";

interface ILoanSimulation {
  amount: number;
  days: number;
  interestRate: number;
  estimatedPaymentDate: Date;
  chargedInterest: number;
  fees: FeeInfo[];
  amountToPay: number;
}

export default class LoanSimulation {
  amount: number;
  days: number;
  interestRate: number;
  estimatedPaymentDate: Date;
  chargedInterest: number;
  fees: FeeInfo[];
  amountToPay: number;

  constructor({
    amount,
    days,
    interestRate,
    estimatedPaymentDate,
    chargedInterest,
    fees,
    amountToPay
  }: ILoanSimulation) {
    this.amount = amount;
    this.days = days;
    this.interestRate = interestRate;
    this.estimatedPaymentDate = estimatedPaymentDate;
    this.chargedInterest = chargedInterest;
    this.fees = fees;
    this.amountToPay = amountToPay;
  }
}