import { LoanSimulation } from "../../../../domain/models";
import { LoanSimulationEntity } from "../entities";
import { FeeInfoMapper } from "./";

export default class LoanSimulationMapper {
  static toModel(entity: LoanSimulationEntity): LoanSimulation {
    return new LoanSimulation({
      amount: entity.amount,
      days: entity.days,
      interestRate: entity.interest_rate,
      estimatedPaymentDate: entity.estimated_payment_date,
      chargedInterest: entity.charged_interest,
      fees: entity.fees.map(fee => FeeInfoMapper.toModel(fee)),
      amountToPay: entity.amount_to_pay
    });
  }
}